var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "10" },
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "el-def",
                      attrs: { placeholder: "제목" },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.refetchData()
                        },
                      },
                      model: {
                        value: _vm.query.filterStr,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "filterStr", $$v)
                        },
                        expression: "query.filterStr",
                      },
                    }),
                    _c("v-date-picker", {
                      staticClass: "el-def",
                      staticStyle: { width: "150px" },
                      attrs: { type: "month", "value-type": "YYYYMM" },
                      model: {
                        value: _vm.query.searchDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "searchDate", $$v)
                        },
                        expression: "query.searchDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.refetchData()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("h5", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(
                          " 등록 리스트 [" + _vm._s(_vm.totalRecords) + "] 건 "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            ref: "refEventTable",
            staticClass: "position-relative",
            staticStyle: { "max-height": "670px" },
            attrs: {
              "primary-key": "id",
              items: _vm.fetchEvents,
              fields: _vm.tableColumns,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.isSortDesc,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.isSortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.isSortDesc = $event
              },
              "row-selected": _vm.onRowSelectedEvent,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(createDate)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("$dateFormatter")(
                              data.value,
                              "YYYY-MM-DD HH:mm"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(userName)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(" " + _vm._s(data.value) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "EditIcon", size: "19" },
                  }),
                  _c("h4", { staticClass: "mb-0 ml-50" }, [_vm._v(" 상세 ")]),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center justify-content-end",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.resetEventData()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "PlusSquareIcon" },
                      }),
                      _c("span", [_vm._v("신규")]),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { disabled: _vm.isSaving, variant: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.saveEvent()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "SaveIcon" },
                      }),
                      _c("span", [_vm._v("저장")]),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeEvent()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "Trash2Icon" },
                      }),
                      _c("span", [_vm._v("삭제")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "validation-observer",
            { ref: "formRules" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "이벤트 유형",
                            "label-for": "event-type",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "이벤트 유형", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        id: "event-type",
                                        options: _vm.codes.eventType,
                                        placeholder: "선택",
                                        reduce: function (option) {
                                          return option.code
                                        },
                                        state: errors.length > 0 ? false : null,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.changeEventType()
                                        },
                                      },
                                      model: {
                                        value: _vm.eventData.eventTypeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.eventData,
                                            "eventTypeId",
                                            $$v
                                          )
                                        },
                                        expression: "eventData.eventTypeId",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "이벤트 화면에서 참여 진행" } },
                        [
                          _c("b-form-checkbox", {
                            attrs: {
                              disabled: "",
                              checked:
                                _vm.eventData.eventPlace === 2 ? true : false,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: { label: "제목", "label-for": "event-title" },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "제목", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "clientName",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.eventData.title,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.eventData, "title", $$v)
                                        },
                                        expression: "eventData.title",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.eventData.eventPlace !== 2 && !_vm.hasEventContents
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "컨텐츠", "label-for": "" } },
                            [
                              _c("b-form-file", {
                                ref: "contentFile",
                                attrs: {
                                  accept: "text/plain,.html",
                                  placeholder: "파일 찾기",
                                },
                                on: { input: _vm.readContentFile },
                              }),
                              _c("validation-provider", {
                                attrs: {
                                  name: "컨텐츠",
                                  rules: {
                                    required: _vm.eventData.isPublic
                                      ? true
                                      : false,
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: false,
                                                expression: "false",
                                              },
                                            ],
                                            model: {
                                              value: _vm.eventData.contents,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.eventData,
                                                  "contents",
                                                  $$v
                                                )
                                              },
                                              expression: "eventData.contents",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  224722814
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-sm-50",
                              staticStyle: { "margin-top": "28px" },
                              attrs: {
                                variant: "primary",
                                disabled:
                                  _vm.eventData.contents === null ||
                                  _vm.eventData.contents.length === 0,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showModal = true
                                },
                              },
                            },
                            [_c("span", [_vm._v("미리보기")])]
                          ),
                          _c(
                            "b-modal",
                            {
                              attrs: {
                                id: "eventPreview",
                                title: "HTML 미리보기",
                              },
                              model: {
                                value: _vm.showModal,
                                callback: function ($$v) {
                                  _vm.showModal = $$v
                                },
                                expression: "showModal",
                              },
                            },
                            [
                              _c("iframe", {
                                ref: "previewFrame",
                                attrs: {
                                  src: _vm.previewUrl,
                                  frameborder: "0",
                                  width: "1264",
                                  height: "740",
                                },
                                on: { load: _vm.loadPreview },
                              }),
                            ]
                          ),
                          _vm.eventData.contents !== null &&
                          _vm.eventData.contents.length > 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mr-sm-50",
                                  staticStyle: { "margin-top": "28px" },
                                  attrs: { variant: "outline-dark" },
                                  on: { click: _vm.downloadContent },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "mr-50",
                                    attrs: { icon: "DownloadIcon" },
                                  }),
                                  _c("span", [_vm._v(" 다운로드")]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "첨부파일",
                            "label-for": "event-files",
                          },
                        },
                        [
                          _c("b-form-file", {
                            attrs: { multiple: "", placeholder: "파일 찾기" },
                            on: { input: _vm.inputFiles },
                            model: {
                              value: _vm.attachFiles,
                              callback: function ($$v) {
                                _vm.attachFiles = $$v
                              },
                              expression: "attachFiles",
                            },
                          }),
                          _vm._l(
                            _vm.eventData.attachFiles,
                            function (item, idx) {
                              return _c(
                                "div",
                                { key: idx },
                                [
                                  _c(
                                    "b-card-text",
                                    { staticClass: "my-50 text-primary" },
                                    [
                                      _vm._v(" " + _vm._s(item.fileName) + " "),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "ml-1",
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                          attrs: {
                                            variant: "outline-secondary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.deleteAttachFile(
                                                item.fileId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-50",
                                            attrs: { icon: "Trash2Icon" },
                                          }),
                                          _c("span", [_vm._v("삭제")]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-sm-50",
                                          attrs: { variant: "outline-dark" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.downloadAttachFile(
                                                item.fileId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-50",
                                            attrs: { icon: "DownloadIcon" },
                                          }),
                                          _c("span", [_vm._v("다운로드")]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label:
                              "Sub 썸네일 (※ 이미지 사이즈는 300 x 210 입니다.)",
                            "label-for": "main-thumbnail-files",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "Sub 썸네일",
                              rules: {
                                required:
                                  _vm.eventData.thumnailFileId === null &&
                                  _vm.eventData.isPublic,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-file", {
                                      attrs: {
                                        accept: "image/*",
                                        placeholder: "파일 찾기",
                                      },
                                      on: { input: _vm.inputThumnail },
                                      model: {
                                        value: _vm.thumnailFile,
                                        callback: function ($$v) {
                                          _vm.thumnailFile = $$v
                                        },
                                        expression: "thumnailFile",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _vm.eventData.thumnailFileId
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-sm-50",
                              staticStyle: { "margin-top": "21px" },
                              attrs: { variant: "outline-dark" },
                              on: { click: _vm.downloadThumbnail },
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "mr-50",
                                attrs: { icon: "DownloadIcon" },
                              }),
                              _c("span", [_vm._v("다운로드")]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "진행 시작 일자",
                            "label-for": "start-date",
                          },
                        },
                        [
                          _c("b-form-datepicker", {
                            model: {
                              value: _vm.eventData.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.eventData, "startDate", $$v)
                              },
                              expression: "eventData.startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "진행 종료 일자",
                            "label-for": "end-date",
                          },
                        },
                        [
                          _c("b-form-datepicker", {
                            model: {
                              value: _vm.eventData.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.eventData, "endDate", $$v)
                              },
                              expression: "eventData.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.eventData.isClose
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "마감 여부",
                                "label-for": "end-date",
                              },
                            },
                            [
                              _c(
                                "h5",
                                {
                                  staticClass: "mt-1",
                                  staticStyle: { color: "darkred" },
                                },
                                [_vm._v(" 이벤트 종료(참여마감) ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "10" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "팝업 여부",
                            "label-for": "notice-is-popup",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              options: _vm.codes.use,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.eventData.isPopup,
                              callback: function ($$v) {
                                _vm.$set(_vm.eventData, "isPopup", $$v)
                              },
                              expression: "eventData.isPopup",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.eventData.isPopup
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "팝업 게시 일자(*팝업 사용 시)",
                                "label-for": "popup-date-fr",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "팝업 게시 일",
                                  rules: {
                                    before: _vm.eventData.popupDateTo,
                                    dateBetween: [
                                      _vm.eventData.startDate,
                                      _vm.eventData.endDate,
                                    ],
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        var failedRules = ref.failedRules
                                        return [
                                          _c("b-form-datepicker", {
                                            attrs: {
                                              placeholder: "팝업 게시 일",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            on: { input: _vm.checkPopupDateFr },
                                            model: {
                                              value: _vm.eventData.popupDateFr,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.eventData,
                                                  "popupDateFr",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "eventData.popupDateFr",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    failedRules.hasOwnProperty(
                                                      "dateBetween"
                                                    )
                                                      ? "팝업 기간은 진행 기간 이내로 제한됩니다."
                                                      : errors[0]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2462952187
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "팝업 종료 일자(*팝업 사용 시)",
                                "label-for": "popup-date-to",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "팝업 종료 일",
                                  rules: {
                                    required:
                                      _vm.eventData.popupDateFr !== null,
                                    after: _vm.eventData.popupDateFr,
                                    dateBetween: [
                                      _vm.eventData.startDate,
                                      _vm.eventData.endDate,
                                    ],
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        var failedRules = ref.failedRules
                                        return [
                                          _c("b-form-datepicker", {
                                            attrs: {
                                              placeholder: "팝업 종료 일",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value: _vm.eventData.popupDateTo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.eventData,
                                                  "popupDateTo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "eventData.popupDateTo",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    failedRules.hasOwnProperty(
                                                      "dateBetween"
                                                    )
                                                      ? "팝업 기간은 진행 기간 이내로 제한됩니다."
                                                      : errors[0]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3275114460
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "팝업다시보지않기(기준)",
                                "label-for": "notice-popup-again-days",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "팝업다시보지않기(기준)",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              id: "notice-popup-again-days",
                                              options: _vm.codes.popupAgainDays,
                                              placeholder: "선택",
                                              reduce: function (option) {
                                                return option.code
                                              },
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.eventData.popupAgainDays,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.eventData,
                                                  "popupAgainDays",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "eventData.popupAgainDays",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1707183172
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.eventData.isPopup
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  "팝업 이미지 (※ 이미지 사이즈는 650 x 380 이내로 제작하세요.)",
                                "label-for": "main-thumbnail-files",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "팝업 이미지",
                                  rules: {
                                    required:
                                      _vm.eventData.isPopup &&
                                      !_vm.eventData.popupImageFileId,
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-file", {
                                            attrs: {
                                              accept: "image/*",
                                              placeholder: "파일 찾기",
                                              disabled:
                                                !_vm.eventData.popupDateFr,
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            on: { input: _vm.inputPopupImage },
                                            model: {
                                              value: _vm.popupImageFile,
                                              callback: function ($$v) {
                                                _vm.popupImageFile = $$v
                                              },
                                              expression: "popupImageFile",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1566011183
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _vm.eventData.popupImageFileId
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mr-sm-50",
                                  staticStyle: { "margin-top": "21px" },
                                  attrs: { variant: "outline-dark" },
                                  on: { click: _vm.downloadPopupImage },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "mr-50",
                                    attrs: { icon: "DownloadIcon" },
                                  }),
                                  _c("span", [_vm._v("다운로드")]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "상단 고정",
                            "label-for": "notice-is-fixed",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              options: _vm.codes.use,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.eventData.isFixed,
                              callback: function ($$v) {
                                _vm.$set(_vm.eventData, "isFixed", $$v)
                              },
                              expression: "eventData.isFixed",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "공개 여부",
                            "label-for": "notice-is-public",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              options: _vm.codes.public,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.eventData.isPublic,
                              callback: function ($$v) {
                                _vm.$set(_vm.eventData, "isPublic", $$v)
                              },
                              expression: "eventData.isPublic",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-2", attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "label",
                            { attrs: { for: "notice-allow-comments" } },
                            [_vm._v("댓글 기능")]
                          ),
                          _c("b-form-radio-group", {
                            attrs: {
                              options: _vm.codes.use,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.eventData.allowComments,
                              callback: function ($$v) {
                                _vm.$set(_vm.eventData, "allowComments", $$v)
                              },
                              expression: "eventData.allowComments",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.eventData.allowComments
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12", md: "5" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "comment-survey-title" } },
                                [_vm._v("투표형 댓글 투표지 선택")]
                              ),
                              _c(
                                "b-badge",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.v-secondary",
                                      modifiers: {
                                        hover: true,
                                        "v-secondary": true,
                                      },
                                    },
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.right",
                                      modifiers: { hover: true, right: true },
                                    },
                                  ],
                                  staticStyle: {
                                    padding: "0px",
                                    "margin-left": "5px",
                                  },
                                  attrs: {
                                    variant: "light-secondary",
                                    title:
                                      "지정: 투표형 댓글 / 미지정: 일반 댓글",
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "InfoIcon" },
                                  }),
                                ],
                                1
                              ),
                              _vm.eventData.isAnswer
                                ? _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        "margin-left": "15px",
                                        color: "#ea5455",
                                      },
                                      attrs: { for: "comment-survey-title" },
                                    },
                                    [
                                      _vm._v(
                                        "참여자가 있어 다른 투표지를 등록할 수 없습니다."
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("b-form-input", {
                                attrs: {
                                  id: "comment-survey-title",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.eventData.commentSurveyTitle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.eventData,
                                      "commentSurveyTitle",
                                      $$v
                                    )
                                  },
                                  expression: "eventData.commentSurveyTitle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.eventData.allowComments
                    ? _c(
                        "b-col",
                        {
                          staticClass: "mb-50",
                          attrs: { cols: "12", md: "4" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-0 mt-md-2",
                              attrs: {
                                disabled: _vm.eventData.isAnswer,
                                variant: "outline-primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.showCommentSurveySelect = true
                                },
                              },
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "mr-50",
                                attrs: { icon: "SearchIcon" },
                              }),
                              _c("span", [_vm._v("투표지 검색")]),
                            ],
                            1
                          ),
                          _vm.eventData.commentSurveyId
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "ml-1 mt-0 mt-md-2",
                                  attrs: { variant: "outline-danger" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.eventData.commentSurveyId = null
                                      _vm.eventData.commentSurveyTitle = null
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "mr-50",
                                    attrs: { icon: "Trash2Icon" },
                                  }),
                                  _c("span", [_vm._v("투표지 제거")]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.eventData.eventPlace !== 1
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "close-count" } }, [
                                _vm._v("참여 마감 횟수"),
                              ]),
                              _c(
                                "b-badge",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.v-secondary",
                                      modifiers: {
                                        hover: true,
                                        "v-secondary": true,
                                      },
                                    },
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.right",
                                      modifiers: { hover: true, right: true },
                                    },
                                  ],
                                  staticStyle: {
                                    padding: "0px",
                                    "margin-left": "5px",
                                  },
                                  attrs: {
                                    variant: "light-secondary",
                                    title: "총 참여횟수 초과 시 자동마감",
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "InfoIcon" },
                                  }),
                                ],
                                1
                              ),
                              _c("cleave", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "close-count",
                                  placeholder: "숫자만 입력 가능",
                                  options: _vm.cleave.person,
                                },
                                model: {
                                  value: _vm.eventData.closeCount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.eventData, "closeCount", $$v)
                                  },
                                  expression: "eventData.closeCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "close-budget" } }, [
                                _vm._v("참여 마감 총액"),
                              ]),
                              _c(
                                "b-badge",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.v-secondary",
                                      modifiers: {
                                        hover: true,
                                        "v-secondary": true,
                                      },
                                    },
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.right",
                                      modifiers: { hover: true, right: true },
                                    },
                                  ],
                                  staticStyle: {
                                    padding: "0px",
                                    "margin-left": "5px",
                                  },
                                  attrs: {
                                    variant: "light-secondary",
                                    title:
                                      "지급포인트/기프티콘 가액 총액 초과 시 자동마감",
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "InfoIcon" },
                                  }),
                                ],
                                1
                              ),
                              _c("cleave", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "close-budget",
                                  placeholder: "숫자만 입력 가능",
                                  options: _vm.cleave.point,
                                },
                                model: {
                                  value: _vm.eventData.closeBudget,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.eventData, "closeBudget", $$v)
                                  },
                                  expression: "eventData.closeBudget",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticStyle: { height: "62px" },
                      attrs: { cols: "12", md: "3" },
                    },
                    [
                      _c(
                        "label",
                        { attrs: { for: "notice-allow-non-member" } },
                        [_vm._v("비회원 참여여부")]
                      ),
                      _c(
                        "b-badge",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.v-danger",
                              modifiers: { hover: true, "v-danger": true },
                            },
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.right",
                              modifiers: { hover: true, right: true },
                            },
                          ],
                          staticStyle: { padding: "0px", "margin-left": "5px" },
                          attrs: {
                            variant: "light-danger",
                            title:
                              "비회원 허용 이벤트페이지에 제한 설정 시 기프티콘 정보가 없어 오류가 날 수 있습니다. 이벤트 페이지와 맞는 사전협의된 값으로 선택해주세요.",
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "AlertCircleIcon" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-start",
                          attrs: { switch: "" },
                          on: { change: _vm.changeAllowNonMember },
                          model: {
                            value: _vm.eventData.allowNonMember,
                            callback: function ($$v) {
                              _vm.$set(_vm.eventData, "allowNonMember", $$v)
                            },
                            expression: "eventData.allowNonMember",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              (_vm.eventData.allowNonMember ? "허용" : "제한") +
                                (_vm.eventData.eventPlace !== 1 &&
                                _vm.eventData.eventPlace !== 4
                                  ? _vm.eventData.allowNonMember
                                    ? " (기프티콘+포인트 가능)"
                                    : " (기프티콘 / 포인트 택 1)"
                                  : "")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.eventData.eventPlace !== 1 &&
                  _vm.eventData.eventPlace !== 4
                    ? _c(
                        "b-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.eventData.randomRewardType === 0,
                              expression: "eventData.randomRewardType === 0",
                            },
                          ],
                          attrs: { cols: "12", md: "3" },
                        },
                        [
                          _c(
                            "label",
                            { attrs: { for: "notice-allow-non-member" } },
                            [_vm._v("지급 기프티콘")]
                          ),
                          _c(
                            "b-badge",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.v-secondary",
                                  modifiers: {
                                    hover: true,
                                    "v-secondary": true,
                                  },
                                },
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.right",
                                  modifiers: { hover: true, right: true },
                                },
                              ],
                              staticStyle: {
                                padding: "0px",
                                "margin-left": "5px",
                              },
                              attrs: {
                                variant: "light-secondary",
                                title:
                                  "비회원 보상. 포인트 없이(0P) 기프티콘만 설정 시 회원에게 기프티콘이 제공됩니다.",
                              },
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "InfoIcon" },
                              }),
                            ],
                            1
                          ),
                          _c("v-select", {
                            attrs: {
                              id: "event-reward-goods-code",
                              options: _vm.codes.rewardGoods,
                              placeholder: "기프티콘 선택",
                              reduce: function (option) {
                                return option.code
                              },
                              clearable: false,
                            },
                            model: {
                              value: _vm.eventData.rewardGoodsCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.eventData, "rewardGoodsCode", $$v)
                              },
                              expression: "eventData.rewardGoodsCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.eventData.eventPlace !== 1 &&
                  _vm.eventData.eventPlace !== 4
                    ? _c(
                        "b-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.eventData.randomRewardType === 0,
                              expression: "eventData.randomRewardType === 0",
                            },
                          ],
                          attrs: { cols: "12", md: "3" },
                        },
                        [
                          _c(
                            "label",
                            { attrs: { for: "notice-allow-non-member" } },
                            [_vm._v("지급 포인트")]
                          ),
                          _c(
                            "b-badge",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.v-secondary",
                                  modifiers: {
                                    hover: true,
                                    "v-secondary": true,
                                  },
                                },
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.right",
                                  modifiers: { hover: true, right: true },
                                },
                              ],
                              staticStyle: {
                                padding: "0px",
                                "margin-left": "5px",
                              },
                              attrs: {
                                variant: "light-secondary",
                                title:
                                  "회원 보상. 기프티콘 없이(꽝) 포인트만 설정 시 회원에게 포인트가 제공됩니다.",
                              },
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "InfoIcon" },
                              }),
                            ],
                            1
                          ),
                          _vm.eventData.allowNonMember &&
                          _vm.eventData.rewardGoodsCode
                            ? _c(
                                "b-badge",
                                {
                                  staticStyle: { "margin-left": "5px" },
                                  attrs: { variant: "light-warning" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        "min " +
                                          _vm.getPointAmount(
                                            _vm.eventData.rewardGoodsCode
                                          ) +
                                          "p"
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !_vm.eventData.allowNonMember &&
                          _vm.eventData.rewardGoodsCode !== "lose" &&
                          _vm.eventData.point > 0
                            ? _c(
                                "b-badge",
                                {
                                  staticStyle: { "margin-left": "5px" },
                                  attrs: { variant: "light-danger" },
                                },
                                [_vm._v(" 기프티콘/포인트 택1 ")]
                              )
                            : _vm._e(),
                          _c(
                            "b-input-group",
                            { staticClass: "input-group-merge" },
                            [
                              _c("b-form-input", {
                                staticClass: "text-right",
                                attrs: {
                                  maxlength: "9",
                                  value: _vm.formatPoint(_vm.eventData.point),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.updatePoint()
                                  },
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    { staticStyle: { width: "2.3rem" } },
                                    [_vm._v(" P ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.eventData.eventPlace !== 1 && _vm.eventData.eventPlace !== 4
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-start",
                          attrs: { cols: "12", md: "9" },
                        },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "is-random-point" } },
                                [_vm._v("포인트/기프티콘 랜덤 지급")]
                              ),
                              _c(
                                "b-badge",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.v-secondary",
                                      modifiers: {
                                        hover: true,
                                        "v-secondary": true,
                                      },
                                    },
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.right",
                                      modifiers: { hover: true, right: true },
                                    },
                                  ],
                                  staticStyle: {
                                    padding: "0px",
                                    "margin-left": "5px",
                                  },
                                  attrs: {
                                    variant: "light-secondary",
                                    title:
                                      "혼합 사용인 경우 회원에게는 포인트가 지급되고 비회원에게는 기프티콘이 지급됩니다. 지급 포인트는 연결된 기프티콘가액 이상으로만 설정 가능합니다.",
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "AlertCircleIcon" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-radio-group",
                                {
                                  staticClass:
                                    "d-flex flex-grow-1 align-items-center mr-2",
                                  staticStyle: { height: "30px" },
                                  attrs: {
                                    options: _vm.codes.randomRewards,
                                    "value-field": "code",
                                    "text-field": "label",
                                  },
                                  on: { change: _vm.changeRandomRewardType },
                                  model: {
                                    value: _vm.eventData.randomRewardType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.eventData,
                                        "randomRewardType",
                                        $$v
                                      )
                                    },
                                    expression: "eventData.randomRewardType",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.eventData.randomRewardType > 0,
                                          expression:
                                            "eventData.randomRewardType > 0",
                                        },
                                      ],
                                      staticStyle: {
                                        "margin-left": "5px",
                                        "padding-left": "10px",
                                        "margin-bottom": "1px",
                                      },
                                      attrs: {
                                        variant: "outline-primary",
                                        size: "sm",
                                      },
                                      on: { click: _vm.addRandomPoint },
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-25",
                                        attrs: { icon: "PlusIcon" },
                                      }),
                                      _c(
                                        "span",
                                        { staticStyle: { height: "14px" } },
                                        [_vm._v("랜덤지급보상 추가")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.eventData.eventPlace !== 1 &&
              _vm.eventData.eventPlace !== 4 &&
              _vm.eventData.randomRewardType > 0
                ? _vm._l(_vm.randomPoints, function (r, idx) {
                    return _c(
                      "b-row",
                      {
                        key: idx,
                        staticStyle: { "margin-top": "5px" },
                        attrs: { id: "random-point-" + idx },
                      },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c(
                              "b-button-toolbar",
                              [
                                _c(
                                  "b-button-group",
                                  { attrs: { size: "sm" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "flat-danger",
                                          pill: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeRandomPoint(idx)
                                          },
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "Trash2Icon" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-input-group",
                                  {
                                    staticClass: "input-group-merge",
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: { size: "sm" },
                                  },
                                  [
                                    _c(
                                      "b-input-group-prepend",
                                      [
                                        _c("b-input-group-text", [
                                          _vm._v(" 지급 확률 "),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("b-form-input", {
                                      staticClass: "text-right",
                                      staticStyle: {
                                        width: "3rem",
                                        height: "30px",
                                      },
                                      attrs: {
                                        value: r.weight,
                                        maxlength: "5",
                                      },
                                      on: {
                                        input: function (event) {
                                          return _vm.updatePercent(idx)
                                        },
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      [
                                        _c(
                                          "b-input-group-text",
                                          { staticStyle: { width: "2.3rem" } },
                                          [_vm._v(" % ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.eventData.randomRewardType === 2 ||
                                _vm.eventData.randomRewardType === 3
                                  ? _c("v-select", {
                                      staticClass: "select-size-sm",
                                      staticStyle: {
                                        "margin-right": "10px",
                                        width: "200px",
                                        height: "30px",
                                      },
                                      attrs: {
                                        id: "event-reward-goods-code",
                                        options: _vm.codes.rewardGoods,
                                        placeholder: "지급 기프티콘",
                                        reduce: function (option) {
                                          return option.code
                                        },
                                        clearable: false,
                                      },
                                      model: {
                                        value: r.rewardGoodsCode,
                                        callback: function ($$v) {
                                          _vm.$set(r, "rewardGoodsCode", $$v)
                                        },
                                        expression: "r.rewardGoodsCode",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.eventData.randomRewardType === 1 ||
                                _vm.eventData.randomRewardType === 3
                                  ? _c(
                                      "b-input-group",
                                      {
                                        staticClass: "input-group-merge",
                                        staticStyle: {
                                          "margin-right": "10px",
                                          width: "200px",
                                        },
                                        attrs: { size: "sm" },
                                      },
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c("b-input-group-text", [
                                              _vm._v(" 지급 포인트 "),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "text-right",
                                          staticStyle: {
                                            width: "5rem",
                                            height: "30px",
                                          },
                                          attrs: {
                                            maxlength: "9",
                                            value: _vm.formatPoint(r.point),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.updateRandomPoint(idx)
                                            },
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticStyle: {
                                                  width: "2.3rem",
                                                },
                                              },
                                              [_vm._v(" P ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.eventData.randomRewardType === 3
                                  ? _c(
                                      "b-input-group",
                                      {
                                        staticClass: "input-group-merge",
                                        staticStyle: { "margin-right": "10px" },
                                        attrs: { size: "sm" },
                                      },
                                      [
                                        r.rewardGoodsCode
                                          ? _c(
                                              "b-badge",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                                attrs: {
                                                  variant: "light-warning",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      "최소 포인트 : " +
                                                        _vm.getPointAmount(
                                                          r.rewardGoodsCode
                                                        ) +
                                                        "p"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("comment-survey-select", {
        attrs: { "show-comment-survey-select": _vm.showCommentSurveySelect },
        on: { close: _vm.commentSurveySelect.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }