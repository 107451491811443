<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-end"
          >
            <b-form-input
              v-model="query.filterStr"
              class="el-def"
              placeholder="제목"
              @keyup.enter="refetchData()"
            />
            <!-- <b-form-datepicker
              v-model="query.createDateFr"
              class="el-def"
              placeholder="진행 기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.createDateTo"
              class="el-def"
              placeholder="진행 기간(To)"
            /> -->
            <v-date-picker
              v-model="query.searchDate"
              type="month"
              value-type="YYYYMM"
              class="el-def"
              style="width: 150px"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              등록 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refEventTable"
        primary-key="id"
        :items="fetchEvents"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        style="max-height: 670px"
        @row-selected="onRowSelectedEvent"
      >
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>

        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>

        <template #cell(userName)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
      </b-table>
    </b-card>

    <b-card
      class="mt-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            상세
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-primary"
            @click.prevent="resetEventData()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span>신규</span>
          </b-button>
          <b-button
            :disabled="isSaving"
            variant="primary"
            class="ml-1"
            @click.prevent="saveEvent()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>저장</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
            @click.prevent="removeEvent()"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span>삭제</span>
          </b-button>
        </b-col>
      </b-row>

      <validation-observer ref="formRules">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="이벤트 유형"
              label-for="event-type"
            >
              <validation-provider
                #default="{ errors }"
                name="이벤트 유형"
                rules="required"
              >
                <v-select
                  id="event-type"
                  v-model="eventData.eventTypeId"
                  :options="codes.eventType"
                  placeholder="선택"
                  :reduce="option => option.code"
                  :state="errors.length > 0 ? false : null"
                  @input="changeEventType()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="이벤트 화면에서 참여 진행"
            >
              <b-form-checkbox
                disabled
                :checked="eventData.eventPlace === 2 ? true : false"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="제목"
              label-for="event-title"
            >
              <validation-provider
                #default="{ errors }"
                name="제목"
                rules="required"
              >
                <b-form-input
                  id="clientName"
                  v-model="eventData.title"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="eventData.eventPlace !== 2 && !hasEventContents">
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="컨텐츠"
              label-for=""
            >
              <b-form-file
                ref="contentFile"
                accept="text/plain,.html"
                placeholder="파일 찾기"
                @input="readContentFile"
              />
              <validation-provider
                #default="{ errors }"
                name="컨텐츠"
                :rules="{ required : eventData.isPublic ? true : false }"
              >
                <b-form-input
                  v-show="false"
                  v-model="eventData.contents"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-button
              variant="primary"
              class="mr-sm-50"
              style="margin-top: 28px"
              :disabled="eventData.contents === null || eventData.contents.length === 0"
              @click="showModal = true"
            >
              <span>미리보기</span>
            </b-button>
            <b-modal
              id="eventPreview"
              v-model="showModal"
              title="HTML 미리보기"
            >
              <iframe
                ref="previewFrame"
                :src="previewUrl"
                frameborder="0"
                width="1264"
                height="740"
                @load="loadPreview"
              />
            </b-modal>
            <b-button
              v-if="eventData.contents !== null && eventData.contents.length > 0"
              variant="outline-dark"
              class="mr-sm-50"
              style="margin-top: 28px"
              @click="downloadContent"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span> 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="첨부파일"
              label-for="event-files"
            >
              <b-form-file
                v-model="attachFiles"
                multiple
                placeholder="파일 찾기"
                @input="inputFiles"
              />
              <div
                v-for="(item, idx) in eventData.attachFiles"
                :key="idx"
              >
                <b-card-text
                  class="my-50 text-primary"
                >
                  {{ item.fileName }}
                  <b-button
                    variant="outline-secondary"
                    class="ml-1"
                    style="margin-right: 10px;"
                    @click.prevent="deleteAttachFile(item.fileId)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span>삭제</span>
                  </b-button>
                  <b-button
                    variant="outline-dark"
                    class="mr-sm-50"
                    @click.prevent="downloadAttachFile(item.fileId)"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>다운로드</span>
                  </b-button>
                </b-card-text>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Sub 썸네일 (※ 이미지 사이즈는 300 x 210 입니다.)"
              label-for="main-thumbnail-files"
            >
              <validation-provider
                #default="{ errors }"
                name="Sub 썸네일"
                :rules="{ required : eventData.thumnailFileId === null && eventData.isPublic }"
              >
                <b-form-file
                  v-model="thumnailFile"
                  accept="image/*"
                  placeholder="파일 찾기"
                  @input="inputThumnail"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-button
              v-if="eventData.thumnailFileId"
              variant="outline-dark"
              class="mr-sm-50"
              style="margin-top: 21px"
              @click="downloadThumbnail"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="진행 시작 일자"
              label-for="start-date"
            >
              <b-form-datepicker
                v-model="eventData.startDate"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="진행 종료 일자"
              label-for="end-date"
            >
              <b-form-datepicker
                v-model="eventData.endDate"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="eventData.isClose"
            cols="12"
            md="3"
          >
            <b-form-group
              label="마감 여부"
              label-for="end-date"
            >
              <h5
                class="mt-1"
                style="color: darkred;"
              >
                이벤트 종료(참여마감)
              </h5>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="10"
          >
            <b-form-group
              label="팝업 여부"
              label-for="notice-is-popup"
            >
              <b-form-radio-group
                v-model="eventData.isPopup"
                :options="codes.use"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="eventData.isPopup">
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="팝업 게시 일자(*팝업 사용 시)"
              label-for="popup-date-fr"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="팝업 게시 일"
                :rules="{
                  before: eventData.popupDateTo,
                  dateBetween: [eventData.startDate, eventData.endDate],
                }"
              >
                <b-form-datepicker
                  v-model="eventData.popupDateFr"
                  placeholder="팝업 게시 일"
                  :state="errors.length > 0 ? false : null"
                  @input="checkPopupDateFr"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '팝업 기간은 진행 기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="팝업 종료 일자(*팝업 사용 시)"
              label-for="popup-date-to"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="팝업 종료 일"
                :rules="{
                  required: eventData.popupDateFr !== null,
                  after: eventData.popupDateFr,
                  dateBetween: [eventData.startDate, eventData.endDate],
                }"
              >
                <b-form-datepicker
                  v-model="eventData.popupDateTo"
                  placeholder="팝업 종료 일"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '팝업 기간은 진행 기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="팝업다시보지않기(기준)"
              label-for="notice-popup-again-days"
            >
              <validation-provider
                #default="{ errors }"
                name="팝업다시보지않기(기준)"
                rules="required"
              >
                <v-select
                  id="notice-popup-again-days"
                  v-model="eventData.popupAgainDays"
                  :options="codes.popupAgainDays"
                  placeholder="선택"
                  :reduce="option => option.code"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="eventData.isPopup">
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="팝업 이미지 (※ 이미지 사이즈는 650 x 380 이내로 제작하세요.)"
              label-for="main-thumbnail-files"
            >
              <validation-provider
                #default="{ errors }"
                name="팝업 이미지"
                :rules="{
                  required: eventData.isPopup && !eventData.popupImageFileId,
                }"
              >
                <b-form-file
                  v-model="popupImageFile"
                  accept="image/*"
                  placeholder="파일 찾기"
                  :disabled="!eventData.popupDateFr"
                  :state="errors.length > 0 ? false : null"
                  @input="inputPopupImage"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-button
              v-if="eventData.popupImageFileId"
              variant="outline-dark"
              class="mr-sm-50"
              style="margin-top: 21px"
              @click="downloadPopupImage"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="상단 고정"
              label-for="notice-is-fixed"
            >
              <b-form-radio-group
                v-model="eventData.isFixed"
                :options="codes.use"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="공개 여부"
              label-for="notice-is-public"
            >
              <b-form-radio-group
                v-model="eventData.isPublic"
                :options="codes.public"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-2"
          >
            <b-form-group>
              <label for="notice-allow-comments">댓글 기능</label>
              <b-form-radio-group
                v-model="eventData.allowComments"
                :options="codes.use"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="eventData.allowComments"
            cols="12"
            md="5"
          >
            <b-form-group>
              <label for="comment-survey-title">투표형 댓글 투표지 선택</label>
              <b-badge
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.right
                variant="light-secondary"
                style="padding: 0px; margin-left: 5px;"
                title="지정: 투표형 댓글 / 미지정: 일반 댓글"
              >
                <feather-icon
                  icon="InfoIcon"
                />
              </b-badge>
              <label
                v-if="eventData.isAnswer"
                for="comment-survey-title"
                style="margin-left: 15px; color: #ea5455"
              >참여자가 있어 다른 투표지를 등록할 수 없습니다.</label>
              <b-form-input
                id="comment-survey-title"
                v-model="eventData.commentSurveyTitle"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="eventData.allowComments"
            cols="12"
            md="4"
            class="mb-50"
          >
            <b-button
              :disabled="eventData.isAnswer"
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click.prevent="showCommentSurveySelect = true"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span>투표지 검색</span>
            </b-button>
            <b-button
              v-if="eventData.commentSurveyId"
              variant="outline-danger"
              class="ml-1 mt-0 mt-md-2"
              @click.prevent="eventData.commentSurveyId = null; eventData.commentSurveyTitle = null; "
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span>투표지 제거</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row v-if="eventData.eventPlace !== 1">
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group>
              <label for="close-count">참여 마감 횟수</label>
              <b-badge
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.right
                variant="light-secondary"
                style="padding: 0px; margin-left: 5px;"
                title="총 참여횟수 초과 시 자동마감"
              >
                <feather-icon
                  icon="InfoIcon"
                />
              </b-badge>
              <cleave
                id="close-count"
                v-model="eventData.closeCount"
                placeholder="숫자만 입력 가능"
                :options="cleave.person"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group>
              <label for="close-budget">참여 마감 총액</label>
              <b-badge
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.right
                variant="light-secondary"
                style="padding: 0px; margin-left: 5px;"
                title="지급포인트/기프티콘 가액 총액 초과 시 자동마감"
              >
                <feather-icon
                  icon="InfoIcon"
                />
              </b-badge>
              <cleave
                id="close-budget"
                v-model="eventData.closeBudget"
                placeholder="숫자만 입력 가능"
                :options="cleave.point"
                class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>

          <b-col
            cols="12"
            md="3"
            style=" height: 62px;"
          >
            <label for="notice-allow-non-member">비회원 참여여부</label>
            <b-badge
              v-b-tooltip.hover.v-danger
              v-b-tooltip.hover.right
              variant="light-danger"
              style="padding: 0px; margin-left: 5px;"
              title="비회원 허용 이벤트페이지에 제한 설정 시 기프티콘 정보가 없어 오류가 날 수 있습니다. 이벤트 페이지와 맞는 사전협의된 값으로 선택해주세요."
            >
              <feather-icon
                icon="AlertCircleIcon"
              />
            </b-badge>

            <b-form-checkbox
              v-model="eventData.allowNonMember"
              class="d-flex align-items-center justify-content-start"
              switch
              @change="changeAllowNonMember"
            >{{ (eventData.allowNonMember ? "허용" : "제한") + ((eventData.eventPlace !== 1 && eventData.eventPlace !== 4)? (eventData.allowNonMember ? " (기프티콘+포인트 가능)" : " (기프티콘 / 포인트 택 1)") : "") }}</b-form-checkbox>

          </b-col>
          <b-col
            v-if="eventData.eventPlace !== 1 && eventData.eventPlace !== 4"
            v-show="eventData.randomRewardType === 0"
            cols="12"
            md="3"
          >
            <label for="notice-allow-non-member">지급 기프티콘</label>
            <b-badge
              v-b-tooltip.hover.v-secondary
              v-b-tooltip.hover.right
              variant="light-secondary"
              style="padding: 0px; margin-left: 5px;"
              title="비회원 보상. 포인트 없이(0P) 기프티콘만 설정 시 회원에게 기프티콘이 제공됩니다."
            >
              <feather-icon
                icon="InfoIcon"
              />
            </b-badge>
            <v-select
              id="event-reward-goods-code"
              v-model="eventData.rewardGoodsCode"
              :options="codes.rewardGoods"
              placeholder="기프티콘 선택"
              :reduce="option => option.code"
              :clearable="false"
            />
          </b-col>
          <b-col
            v-if="eventData.eventPlace !== 1 && eventData.eventPlace !== 4"
            v-show="eventData.randomRewardType === 0"
            cols="12"
            md="3"
          >
            <label for="notice-allow-non-member">지급 포인트</label>
            <b-badge
              v-b-tooltip.hover.v-secondary
              v-b-tooltip.hover.right
              variant="light-secondary"
              style="padding: 0px; margin-left: 5px;"
              title="회원 보상. 기프티콘 없이(꽝) 포인트만 설정 시 회원에게 포인트가 제공됩니다."
            >
              <feather-icon
                icon="InfoIcon"
              />
            </b-badge>
            <b-badge
              v-if="eventData.allowNonMember && eventData.rewardGoodsCode"
              style="margin-left: 5px;"
              variant="light-warning"
            >
              {{ "min " + getPointAmount(eventData.rewardGoodsCode) + "p" }}
            </b-badge>
            <b-badge
              v-if="!eventData.allowNonMember && (eventData.rewardGoodsCode!== 'lose') && eventData.point > 0"
              style="margin-left: 5px;"
              variant="light-danger"
            >
              기프티콘/포인트 택1
            </b-badge>

            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                maxlength="9"
                class="text-right"
                :value="formatPoint(eventData.point)"
                @input="updatePoint()"
              />

              <b-input-group-append>
                <b-input-group-text
                  style="width: 2.3rem;"
                >
                  P
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>

        </b-row>
        <b-row v-if="eventData.eventPlace !== 1 && eventData.eventPlace !== 4">
          <b-col
            cols="12"
            md="9"
            class="d-flex align-items-center justify-content-start"
          >
            <b-form-group>
              <label for="is-random-point">포인트/기프티콘 랜덤 지급</label>
              <b-badge
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.right
                variant="light-secondary"
                style="padding: 0px; margin-left: 5px;"
                title="혼합 사용인 경우 회원에게는 포인트가 지급되고 비회원에게는 기프티콘이 지급됩니다. 지급 포인트는 연결된 기프티콘가액 이상으로만 설정 가능합니다."
              >
                <feather-icon
                  icon="AlertCircleIcon"
                />
              </b-badge>
              <b-form-radio-group
                v-model="eventData.randomRewardType"
                :options="codes.randomRewards"
                value-field="code"
                text-field="label"
                style="height: 30px;"
                class="d-flex flex-grow-1 align-items-center mr-2"
                @change="changeRandomRewardType"
              >
                <b-button
                  v-show="eventData.randomRewardType > 0"
                  variant="outline-primary"
                  size="sm"
                  style="margin-left:5px; padding-left: 10px; margin-bottom: 1px"
                  @click="addRandomPoint"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span style="height:14px">랜덤지급보상 추가</span>
                </b-button>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <template v-if="(eventData.eventPlace !== 1 && eventData.eventPlace !== 4) && eventData.randomRewardType > 0">
          <b-row
            v-for="(r, idx) in randomPoints"
            :id="`random-point-${idx}`"
            :key="idx"
            style="margin-top: 5px"
          >
            <b-col
              cols="12"
              md="12"
            >
              <b-button-toolbar>
                <b-button-group size="sm">
                  <b-button
                    variant="flat-danger"
                    pill
                    @click="removeRandomPoint(idx)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                    />
                  </b-button>
                </b-button-group>

                <b-input-group
                  class="input-group-merge"
                  size="sm"
                  style=" margin-right: 10px;"
                >
                  <b-input-group-prepend>
                    <b-input-group-text>
                      지급 확률
                    </b-input-group-text>
                  </b-input-group-prepend>

                  <b-form-input
                    :value="r.weight"
                    maxlength="5"
                    class="text-right"
                    style="width: 3rem; height: 30px;"
                    @input="event => updatePercent(idx)"
                  />
                  <b-input-group-append>
                    <b-input-group-text
                      style="width: 2.3rem;"
                    >
                      %
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>

                <v-select
                  v-if="eventData.randomRewardType === 2 || eventData.randomRewardType === 3"
                  id="event-reward-goods-code"
                  v-model="r.rewardGoodsCode"
                  :options="codes.rewardGoods"
                  placeholder="지급 기프티콘"
                  :reduce="option => option.code"
                  class="select-size-sm"
                  style=" margin-right: 10px; width: 200px; height: 30px;"
                  :clearable="false"
                />

                <b-input-group
                  v-if="eventData.randomRewardType === 1 || eventData.randomRewardType === 3"
                  class="input-group-merge"
                  size="sm"
                  style=" margin-right: 10px; width: 200px; "
                >
                  <b-input-group-prepend>
                    <b-input-group-text>
                      지급 포인트
                    </b-input-group-text>
                  </b-input-group-prepend>

                  <b-form-input
                    maxlength="9"
                    class="text-right"
                    style="width: 5rem; height: 30px;"
                    :value="formatPoint(r.point)"
                    @input="updateRandomPoint(idx)"
                  />
                  <b-input-group-append>
                    <b-input-group-text
                      style="width: 2.3rem;"
                    >
                      P
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>

                <b-input-group
                  v-if="eventData.randomRewardType === 3"
                  class="input-group-merge"
                  size="sm"
                  style="margin-right: 10px;"
                >
                  <b-badge
                    v-if="r.rewardGoodsCode"
                    class="d-flex align-items-center"
                    variant="light-warning"
                  >
                    {{ "최소 포인트 : " + getPointAmount(r.rewardGoodsCode) + "p" }}
                  </b-badge>
                </b-input-group>
              </b-button-toolbar>
            </b-col>
          </b-row>
        </template>
      </validation-observer>
    </b-card>

    <comment-survey-select
      :show-comment-survey-select="showCommentSurveySelect"
      @close="commentSurveySelect.close"
    />
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import {
  BCardText, BFormFile, BBadge, VBTooltip,
  BButtonToolbar, BButtonGroup, BButton, BFormInput, BInputGroup,
  BInputGroupPrepend, BInputGroupAppend, BInputGroupText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, getCurrentInstance, nextTick,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, requiredIf, before, dateBetween,
} from '@validations'
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import { saveAs } from "file-saver"
import Cleave from 'vue-cleave-component'
import CommentSurveySelect from '../../contents/comment-survey-manage/CommentSurveySelect.vue'

export default {
  components: {
    Cleave,
    vSelect,
    BCardText,
    BFormFile,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
    CommentSurveySelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    onMounted(() => {
      fetchEventType()
    })

    const previewUrl = `${process.env.VUE_APP_PREVIEW_URL}/#/app/preview`
    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const cleave = ref({
      point: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        prefix: 'P',
        tailPrefix: true,
      },
      person: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      percent: {
        numeral: true,
        prefix: '%',
        tailPrefix: true,
      },
    })

    const showModal = ref(false)
    const previewFrame = ref(null)
    const hasEventContents = ref(false)

    const eventInitState = {
      id: null,
      title: null,
      noticeType: 2,
      eventTypeId: null,
      contents: null,
      attachFiles: [],
      thumnailFileId: null,
      startDate: dayjs().format('YYYY-MM-DD'),
      endDate: dayjs().add(1, 'month').format('YYYY-MM-DD'),
      isFixed: false,
      isPublic: true,
      allowComments: true,
      allowNonMember: false,
      eventPlace: null,
      point: 0,
      rewardGoodsCode: null,
      closeCount: 0,
      closeBudget: 0,
      isRandomPoint: false,
      randomRewardType: 0,
      isPopup: false,
      popupDateFr: null,
      popupDateTo: null,
      popupImageFileId: null,
      popupAgainDays: 0,

      commentSurveyId: null,
      commentSurveyTitle: null,
      isAnswer: false,
    }

    const eventData = ref({ ...eventInitState })

    const resetEventData = () => {
      Object.assign(eventData.value, eventInitState)
      if (codes.value.rewardGoods !== undefined) {
        if (codes.value.rewardGoods.length > 0) {
          eventData.value.rewardGoodsCode = 'lose'
        }
      }

      refs.formRules.reset()
      refEventTable.value.clearSelected()

      if (eventData.value.eventPlace !== 2 && !(hasEventContents.value) && contentFile.value) {
        contentFile.value.reset()
      }

      attachFiles.value = []
      thumnailFile.value = []
      popupImageFile.value = []
    }

    const randomPoints = ref([])

    // Set Codes
    const codes = ref({
      eventType: [],

      use: [
        { label: '사용', code: true },
        { label: '사용안함', code: false },
      ],
      public: [
        { label: '공개', code: true },
        { label: '비공개', code: false },
      ],
      popupAgainDays: [
        { label: '다시 보지 않기', code: 0 },
        { label: '5일동안 보지 않기', code: 5 },
        { label: '10일동안 보지 않기', code: 10 },
      ],
      randomRewards: [
        { label: '랜덤 기프티콘 사용', code: 2, disabled: false },
        { label: '랜덤 포인트 사용', code: 1, disabled: false },
        { label: '혼합 사용', code: 3, disabled: true },
        { label: '사용 안함', code: 0, disabled: false },
      ],
    })
    const delMsg = '삭제된 기프티콘입니다.'
    // Set Codes End.

    // Query Data
    const query = ref({
      filterStr: null,
      searchDate: null,
    })
    // Query Data End.

    // Main Table Config
    const refEventTable = ref(null)
    const sortBy = ref('id')
    const isSortDesc = ref(true)
    const totalRecords = ref(0)
    const isSaving = ref(false)
    const tableColumns = [
      {
        key: 'id', label: 'ID', sortable: true, thStyle: { width: '7%' },
      },
      { key: 'title', label: '제목', sortable: true },
      {
        key: 'liveDate', label: '진행기간', sortable: true, thStyle: { width: '14%' },
      },
      {
        key: 'viewCnt', label: '조회수', sortable: true, thStyle: { width: '6%' },
      },
      {
        key: 'createDate', label: '등록일시', sortable: true, thStyle: { width: '12%' },
      },
    ]

    const refetchData = () => {
      refEventTable.value.refresh()
    }

    const onRowSelectedEvent = items => {
      const item = items[0]

      if (item && item.id) {
        fetchEvent(item.id)
      }
    }

    // File config
    const attachFiles = ref([]) // 첨부파일 목록
    const contentFile = ref(null) // 메인 컨텐츠 파일
    const thumnailFile = ref([])
    const popupImageFile = ref([])

    const inputPopupImage = files => {
      popupImageFile.value = files
    }

    const inputThumnail = files => {
      thumnailFile.value = files
    }

    const inputFiles = files => {
      attachFiles.value = files
    }

    const readContentFile = e => {
      if (!e) return

      if (e.type !== 'text/html') {
        pushToast('danger', '잘못된 파일 형식입니다.')

        contentFile.value.reset()
      } else {
        const reader = new FileReader()

        reader.onload = event => {
          eventData.value.contents = event.target.result
        }

        reader.readAsText(e)
      }
    }

    const fetchEventType = () => {
      axios.get(`/fa/event/codes`)
        .then(rs => {
          codes.value.eventType = rs.data
          fetchRewardGoods()
        })
        .catch(() => {
        })
    }

    const fetchRewardGoods = () => {
      axios.get('/fa/event/reward-goods')
        .then(rs => {
          const rewardGoods = rs.data
          // 기본값 'lose' 추가 (꽝)
          const defaultLoseOption = { code: 'lose', label: '꽝', pointAmount: 0 }
          codes.value.rewardGoods = [defaultLoseOption, ...rewardGoods]

          // 기프티콘 데이터가 로드되면 기본값 지정
          eventData.value.rewardGoodsCode = 'lose'
        })
        .catch(() => {
          pushToast('danger', '이벤트 전용 기프티콘 목록을 불러오는데 실패하였습니다.')
        })
    }

    // Fetch Data
    const fetchEvent = id => {
      axios.get(`/fa/event/${id}`)
        .then(rs => {
          eventData.value = rs.data
          if (eventData.value.commentSurveyId) {
            eventData.value.commentSurveyTitle = `[${eventData.value.commentSurveyId}] ${eventData.value.commentSurveyTitle}`
          }

          // 구 데이터 비회원 참여여부 값 갱신
          if (eventData.value.allowNonMember == null) {
            eventData.value.allowNonMember = false
          }

          // 구 데이터 랜덤 지급 값 갱신
          if (eventData.value.randomRewardType == null) {
            if (eventData.value.isRandomPoint) {
              eventData.value.randomRewardType = 1
            } else {
              eventData.value.randomRewardType = 0
            }
          }

          // 기프티콘 값이 null인 경우 기본값(꽝)으로 설정
          if (eventData.value.rewardGoodsCode == null) {
            eventData.value.rewardGoodsCode = 'lose'
          }

          // 기프티콘 코드가 삭제된 경우 Code 대신 삭제된 기프티콘 문구 노출
          if (!codes.value.rewardGoods.some(op => op.code === eventData.value.rewardGoodsCode)) {
            eventData.value.rewardGoodsCode = delMsg
          }

          changeEventType()
          changeAllowNonMember()

          if (rs.data.randomPoints) {
            randomPoints.value = rs.data.randomPoints

            for (let i = 0; i < randomPoints.value.length; i += 1) {
              // 기프티콘 값이 null인 경우 기본값(꽝)으로 설정
              if (randomPoints.value[i].rewardGoodsCode == null) { randomPoints.value[i].rewardGoodsCode = 'lose' }
              // 기프티콘 코드가 삭제된 경우 Code 대신 삭제된 기프티콘 문구 노출
              if (!codes.value.rewardGoods.some(op => op.code === randomPoints.value[i].rewardGoodsCode)) { randomPoints.value[i].rewardGoodsCode = delMsg }
            }
          } else randomPoints.value = []
        })
        .catch(() => {
          pushToast('danger', '데이터 조회에 실패하였습니다.')
        })
    }

    const fetchEvents = (ctx, callback) => {
      axios.post('/fa/event/list', {
        search: {
          filterStr: query.value.filterStr,
          searchDate: query.value.searchDate ? dayjs(query.value.searchDate).date(1).format('YYYY-MM-DD') : null,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data

          callback(items)

          totalRecords.value = totalRecord

          resetEventData()
        })
        .catch(() => {
          pushToast('danger', '데이터 조회에 실패하였습니다.')
        })
    }

    // Put data
    const saveEvent = () => {
      const validPromise = refs.formRules.validate()

      validPromise.then(valid => {
        if (!valid) return

        // 이벤트 공지 타입, 댓글 추첨보상타입이 아닌 경우, 지급 보상 설정 필수
        if (eventData.value.eventPlace !== 1
            && eventData.value.eventPlace !== 4) {
          if (eventData.value.randomRewardType === 0) {
            // 랜덤 값 초기화
            if (randomPoints.value.length) {
              randomPoints.value = []
            }
            /// /// 비회원 허용인 경우 :
            if (eventData.value.allowNonMember) {
              // 1. 기프티콘 값 필수
              if (eventData.value.rewardGoodsCode === 'lose' || eventData.value.rewardGoodsCode === '') {
                pushToast('danger', '지급 기프티콘을 입력해 주세요.')
                return
              }
              // 2. 포인트 값은 0(회원에게 기프티콘으로 지급)이거나, 기프티콘 가액보다 많아야 함
              if ((eventData.value.point < getPointAmount(eventData.value.rewardGoodsCode)) && (eventData.value.point !== 0)) {
                pushToast('danger', '포인트 미지급이 아닌 경우 기프티콘 가액 이상이어야 합니다.')
                return
              }
            } else {
              /// /// 비회원 제한인 경우 :
              // 1. 기프티콘, 포인트 모두 없는 경우 :
              if ((eventData.value.rewardGoodsCode === 'lose') && (eventData.value.point === 0)) {
                pushToast('danger', '지급 기프티콘/포인트 중 한가지를 입력해주세요.')
                return
              }
              // 2. 기프티콘, 포인트 모두 있는 경우 : 둘 중 한가지만 지정 되어야 함
              if ((eventData.value.rewardGoodsCode !== 'lose') && (eventData.value.point !== 0)) {
                pushToast('danger', '지급 기프티콘/포인트 중 한가지만 입력해주세요.')
                return
              }
            }
          } else if (randomPoints.value.length) {
            const totalWeight = randomPoints.value.reduce((f, l) => f + l.weight, 0)
            const totalGoodsAmount = randomPoints.value.reduce((f, l) => f + getPointAmount(l.rewardGoodsCode), 0)
            const totalPointAmount = randomPoints.value.reduce((f, l) => f + getPointAmount(l.point), 0)

            if (totalWeight !== 100) {
              pushToast('danger', '랜덤 포인트/기프티콘 지급 시, 확률의 총합은 100%가 되어야 합니다.')
              return
            }
            if (eventData.value.randomRewardType === 2 && totalGoodsAmount === 0) {
              pushToast('danger', '랜덤 기프티콘 지급 시, 유효한 기프티콘이 하나 이상 지정되어야 합니다.')
              return
            }
            if (eventData.value.randomRewardType === 1 && totalPointAmount === 0) {
              pushToast('danger', '랜덤 포인트 지급 시, 유효한 포인트가 하나 이상 지정되어야 합니다.')
              return
            }
            if (eventData.value.allowNonMember && totalGoodsAmount === 0) {
              pushToast('danger', '비회원 허용인 경우 유효한 기프티콘이 하나 이상 지정되어야 합니다.')
              return
            }
            if (!eventData.value.allowNonMember && totalPointAmount === 0 && eventData.value.randomRewardType !== 2) {
              pushToast('danger', '랜덤 기프티콘 사용이 아닌 회원 전용인 경우 유효한 포인트가 하나 이상 지정되어야 합니다.')
              return
            }

            // 랜덤 유형 별 포인트 처리
            // 1. 랜덤 기프티콘 : 포인트 클리어
            if (eventData.value.randomRewardType === 2) {
              for (let i = 0; i < randomPoints.value.length; i += 1) {
                randomPoints.value[i].point = 0
              }
            }
            // 2. 랜덤 포인트 : 기프티콘 클리어
            if (eventData.value.randomRewardType === 1) {
              for (let i = 0; i < randomPoints.value.length; i += 1) {
                randomPoints.value[i].rewardGoodsCode = ''
              }
            }
            // 3. 혼합
            if (eventData.value.randomRewardType === 3) {
              for (let i = 0; i < randomPoints.value.length; i += 1) {
                if (randomPoints.value[i].point < getPointAmount(randomPoints.value[i].rewardGoodsCode)) {
                  pushToast('danger', '혼합 사용 시, 포인트는 기프티콘 가액보다 낮을 수 없습니다. (0p 지정 불가)')
                  return
                }
              }
            }
          } else if (eventData.value.randomRewardType > 0 && randomPoints.value.length === 0) {
            pushToast('danger', '랜덤 포인트/기프티콘 지급 경우의 수를 설정해 주세요.')
            return
          }
        }

        const { id } = eventData.value

        if (id) {
          updateEvent()
        } else {
          createEvent()
        }
      })
    }

    const createEvent = () => {
      isSaving.value = true
      const formdata = new FormData()

      formdata.append("Title", eventData.value.title)
      formdata.append("EventTypeId", eventData.value.eventTypeId)
      formdata.append("Contents", eventData.value.contents === null ? '' : eventData.value.contents)
      formdata.append("StartDate", eventData.value.startDate)
      formdata.append("EndDate", eventData.value.endDate)
      formdata.append("IsFixed", eventData.value.isFixed)
      formdata.append("IsPublic", eventData.value.isPublic)
      formdata.append("AllowComments", eventData.value.allowComments)
      formdata.append("AllowNonMember", eventData.value.allowNonMember)
      formdata.append("Point", (eventData.value.eventPlace !== 1 && eventData.value.eventPlace !== 4) && eventData.value.randomRewardType === 0 ? eventData.value.point : 0)
      formdata.append("RewardGoodsCode", (eventData.value.eventPlace !== 1 && eventData.value.eventPlace !== 4) && eventData.value.randomRewardType === 0 && eventData.value.rewardGoodsCode !== 'lose' ? eventData.value.rewardGoodsCode : '')
      formdata.append("CloseCount", eventData.value.eventPlace === 1 ? 0 : Number(eventData.value.closeCount.replace(/[^0-9]/g, '')))
      formdata.append("CloseBudget", eventData.value.eventPlace === 1 ? 0 : Number(eventData.value.closeBudget.replace(/[^0-9]/g, '')))
      formdata.append("IsRandomPoint", eventData.value.isRandomPoint)
      formdata.append("RandomRewardType", eventData.value.randomRewardType)
      formdata.append("IsPopup", eventData.value.isPopup)
      formdata.append("PopupDateFr", eventData.value.popupDateFr ? eventData.value.popupDateFr : eventData.value.startDate)
      formdata.append("PopupDateTo", eventData.value.popupDateTo ? eventData.value.popupDateTo : eventData.value.endDate)
      formdata.append("PopupAgainDays", eventData.value.popupAgainDays)

      formdata.append("thumnail", thumnailFile.value)
      formdata.append("popupImage", popupImageFile.value)

      for (let i = 0; i < attachFiles.value.length; i += 1) {
        formdata.append("attachFiles", attachFiles.value[i])
      }

      if (eventData.value.allowComments && eventData.value.commentSurveyId) formdata.append("commentSurveyId", eventData.value.commentSurveyId)

      axios.post("/fa/event", formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(rs => {
          const eventId = rs.data.id

          updateRandomRewards(eventId)

          pushToast('success', '이벤트 등록 완료')
          refetchData()
          isSaving.value = false
        })
        .catch(() => {
          pushToast('danger', '데이터를 저장하는 과정에서 오류가 발생하였습니다.')
        })
    }

    const updateEvent = () => {
      const formdata = new FormData()

      formdata.append("Id", eventData.value.id)
      formdata.append("Title", eventData.value.title)
      formdata.append("EventTypeId", eventData.value.eventTypeId)
      formdata.append("Contents", eventData.value.contents)
      formdata.append("StartDate", eventData.value.startDate)
      formdata.append("EndDate", eventData.value.endDate)
      formdata.append("IsFixed", eventData.value.isFixed)
      formdata.append("IsPublic", eventData.value.isPublic)
      formdata.append("AllowComments", eventData.value.allowComments)
      formdata.append("AllowNonMember", eventData.value.allowNonMember)
      formdata.append("Point", (eventData.value.eventPlace !== 1 && eventData.value.eventPlace !== 4) && eventData.value.randomRewardType === 0 ? eventData.value.point : 0)
      formdata.append("RewardGoodsCode", (eventData.value.eventPlace !== 1 && eventData.value.eventPlace !== 4) && eventData.value.randomRewardType === 0 && eventData.value.rewardGoodsCode !== 'lose' ? eventData.value.rewardGoodsCode : '')
      formdata.append("CloseCount", eventData.value.eventPlace === 1 ? 0 : Number(eventData.value.closeCount.replace(/[^0-9]/g, '')))
      formdata.append("CloseBudget", eventData.value.eventPlace === 1 ? 0 : Number(eventData.value.closeBudget.replace(/[^0-9]/g, '')))
      formdata.append("IsRandomPoint", eventData.value.isRandomPoint)
      formdata.append("RandomRewardType", eventData.value.randomRewardType)
      formdata.append("IsPopup", eventData.value.isPopup)
      formdata.append("PopupDateFr", eventData.value.popupDateFr ? eventData.value.popupDateFr : eventData.value.startDate)
      formdata.append("PopupDateTo", eventData.value.popupDateTo ? eventData.value.popupDateTo : eventData.value.endDate)
      formdata.append("PopupAgainDays", eventData.value.popupAgainDays)

      if (thumnailFile.value) formdata.append("thumnail", thumnailFile.value)
      if (popupImageFile.value) formdata.append("popupImage", popupImageFile.value)

      for (let i = 0; i < attachFiles.value.length; i += 1) {
        formdata.append("attachFiles", attachFiles.value[i])
      }

      if (eventData.value.allowComments && eventData.value.commentSurveyId) formdata.append("commentSurveyId", eventData.value.commentSurveyId)

      axios.put("/fa/event", formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(rs => {
          const eventId = rs.data.id

          updateRandomRewards(eventId)

          pushToast('success', '이벤트 저장 완료')

          if (thumnailFile.value != null && thumnailFile.value.length > 0) deletePrevThumnail(eventData.value.thumnailFileId)
          if (popupImageFile.value != null && popupImageFile.value.length > 0) deletePrevPopupImage(eventData.value.popupImageFileId)
          refetchData()
        })
        .catch(() => {
          pushToast('danger', '데이터를 저장하는 과정에서 오류가 발생하였습니다.')
        })
    }

    const updateRandomRewards = eventId => {
      const rps = randomPoints.value.map(x => ({
        point: x.point,
        rewardGoodsCode: (x.rewardGoodsCode === 'lose' ? '' : x.rewardGoodsCode),
        weight: x.weight,
      }))

      axios.patch(`/fa/event/update-extends/${eventId}`, { randomPoints: rps })
        .then(() => {
        })
        .catch(() => {
          pushToast('danger', '랜덤포인트 지급을 등록하는 과정에서 오류가 발생하였습니다.')
        })
    }

    const deletePrevThumnail = fileId => {
      axios.delete(`/fa/event/file/${fileId}`)
        .then(() => {
        })
        .catch(() => {
        })
    }

    const deletePrevPopupImage = fileId => {
      axios.delete(`/fa/event/popup-image/${fileId}`)
        .then(() => {
        })
        .catch(() => {
        })
    }

    const removeEvent = () => {
      if (!eventData.value.id) {
        pushToast('warning', '삭제할 대상을 선택하세요.')

        return
      }

      bvModal
        .msgBoxConfirm('삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.delete(`/fa/event/${eventData.value.id}`)
            .then(() => {
              pushToast('success', '데이터 삭제 완료')

              refetchData()
            })
            .catch(() => {
              pushToast('danger', '데이터를 삭제하는 과정에서 오류가 발생하였습니다.')
            })
        })
    }

    const downloadContent = () => {
      axios.get(`/fa/event/${eventData.value.id}/content`, { responseType: 'blob' })
        .then(rs => {
          const mimeType = {
            type: "text/plain",
          }

          const blob = new Blob([rs.data], mimeType)

          const fileName = eventData.value.title

          saveAs(blob, `${fileName}.html`)
        })
        .catch(() => {
        })
    }

    const downloadPopupImage = () => {
      axios.get(`/fa/attachfile/${eventData.value.popupImageFileId}`)
        .then(rs => {
          if (rs.data && rs.data.filePath) {
            window.open(rs.data.filePath)
          } else {
            pushToast('danger', '파일 조회에 실패하였습니다.')
          }
        })
        .catch(() => {
        })
    }

    // const downloadThumbnail = () => {
    //   axios.get(`/fa/attachfile/${eventData.value.thumnailFileId}/download`, { responseType: 'blob' })
    //     .then(rs => {
    //       const blob = new Blob([rs.data])

    //       const name = rs.headers["content-disposition"]
    //         .split("filename=")[1]
    //         .replace(/"/g, "")

    //       saveAs(blob, `${decodeURI(name)}`)
    //     })
    //     .catch(() => {
    //     })
    // }

    const downloadThumbnail = () => {
      axios.get(`/fa/attachfile/${eventData.value.thumnailFileId}`)
        .then(rs => {
          if (rs.data && rs.data.filePath) {
            window.open(rs.data.filePath)
          } else {
            pushToast('danger', '파일 조회에 실패하였습니다.')
          }
        })
        .catch(() => {
        })
    }

    const downloadAttachFile = fileId => {
      axios.get(`/fa/attachfile/${fileId}/download`, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])

          const name = rs.headers["content-disposition"]
            .split("filename=")[1]
            .replace(/"/g, "")

          saveAs(blob, `${decodeURI(name)}`)
        })
        .catch(() => {
        })
    }

    const deleteAttachFile = fileId => {
      bvModal
        .msgBoxConfirm('첨부 파일 삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.delete(`/fa/event/file/${fileId}`)
            .then(() => {
              pushToast('success', '첨부 파일 삭제 완료')

              fetchEvent(eventData.value.id)
            })
            .catch(() => {
            })
        })
    }

    const changeEventType = () => {
      const { eventPlace, eventContents } = codes.value.eventType.find(e => e.code === eventData.value.eventTypeId)

      eventData.value.eventPlace = eventPlace

      // 선택한 이벤트에 연결된 컨텐츠가 있는 경우 관리자가 입력하지 않도록 차단
      hasEventContents.value = (eventContents !== null)
    }

    const changeAllowNonMember = () => {
      const { allowNonMember } = eventData.value

      if (allowNonMember === false) {
        // 제한 (회원 전용)
        codes.value.randomRewards.find(x => x.code === 1).disabled = false
        codes.value.randomRewards.find(x => x.code === 3).disabled = true
        if (eventData.value.randomRewardType === 3) {
          // 혼합 사용 불가
          eventData.value.randomRewardType = 1
          changeRandomRewardType()
        }
      } else if (allowNonMember === true) {
        // 허용 (회원 + 비회원 대상)
        codes.value.randomRewards.find(x => x.code === 1).disabled = true
        codes.value.randomRewards.find(x => x.code === 3).disabled = false
        if (eventData.value.randomRewardType === 1) {
        // 랜덤 포인트 사용 불가 (기프티콘 지정 필수)
          eventData.value.randomRewardType = 3
          changeRandomRewardType()
        }
      }
    }

    const changeRandomRewardType = () => {
      const { randomRewardType } = eventData.value

      if (randomRewardType === 0) {
        // 사용 안 함
        eventData.value.isRandomPoint = false
        // randomPoints.value = []
      } else if (randomRewardType === 1) {
        // 랜덤 포인트 사용
        eventData.value.isRandomPoint = true
        // eventData.value.point = 0
        // eventData.value.rewardGoodsCode = 'lose'
      } else if (randomRewardType === 2) {
        // 랜덤 기프티콘 사용
        eventData.value.isRandomPoint = true
        // eventData.value.point = 0
        // eventData.value.rewardGoodsCode = 'lose'
      } else if (randomRewardType === 3) {
        // 혼합 사용
        eventData.value.isRandomPoint = true
        // eventData.value.point = 0
        // eventData.value.rewardGoodsCode = 'lose'
      }
    }

    const addRandomPoint = () => {
      if (!eventData.value.id) {
        pushToast('warning', '이벤트 등록(저장)후 추가 가능합니다.')
        return
      }

      randomPoints.value.push({
        point: 0,
        rewardGoodsCode: 'lose',
        weight: 0,
      })
    }

    const removeRandomPoint = idx => {
      randomPoints.value.splice(idx, 1)
    }

    const loadPreview = e => {
      if (e.type === 'load') previewFrame.value.contentWindow.postMessage(eventData.value.contents, '*')
    }

    const checkPopupDateFr = () => {
    }

    const formatPoint = value => {
      if (!value) return '0'
      let tmp = value
      if (typeof tmp !== "number") {
        tmp = tmp.replace(/\D/g, '')
        tmp = parseInt(tmp, 10)
      }
      tmp = tmp.toLocaleString()
      return tmp
    }

    const updatePoint = () => {
      let tmp = event.target.value
      if (typeof tmp !== "number") {
        tmp = tmp.replace(/\D/g, '')
        if (tmp === '') { tmp = '0' }
        tmp = parseInt(tmp, 10)
      }
      // // 비회원 허용이면서 기프티콘을 선택한 경우 : 기프티콘 가액 이상을 선택하도록 제한 => 입력과정이 불편해서 나중에 체크..
      // const giftCode = eventData.value.rewardGoodsCode
      // if (eventData.value.allowNonMember && giftCode !== null && giftCode !== 'lose') {
      //   const gift = codes.value.rewardGoods.find(x => x.code === giftCode)
      //   if (gift !== undefined && tmp < gift.pointAmount) {
      //     tmp = gift.pointAmount
      //   }
      // }

      nextTick(() => {
        event.target.value = tmp.toLocaleString()
        eventData.value.point = tmp
      })
    }

    const updateRandomPoint = idx => {
      let tmp = event.target.value
      if (typeof tmp !== "number") {
        tmp = tmp.replace(/\D/g, '')
        if (tmp === '') { tmp = '0' }
        tmp = parseInt(tmp, 10)
      }
      // // 혼합사용이면서 기프티콘을 선택한 경우 : 기프티콘 가액 이상을 선택하도록 제한 => 입력과정이 불편해서 나중에 체크..
      // const giftCode = randomPoints.value[idx].rewardGoodsCode
      // if (eventData.value.randomRewardType === 3 && giftCode !== null && giftCode !== 'lose') {
      //   const gift = codes.value.rewardGoods.find(x => x.code === giftCode)
      //   if (gift !== undefined && tmp < gift.pointAmount) {
      //     tmp = gift.pointAmount
      //   }
      // }

      nextTick(() => {
        event.target.value = tmp.toLocaleString()
        randomPoints.value[idx].point = tmp
      })
    }

    const updatePercent = idx => {
      let tmp = event.target.value
      if (typeof tmp !== "number") {
        tmp = tmp.replace(/[^\d.]/g, '')
        if (tmp === '' || tmp === '.') { tmp = '0' }
        // 마지막 자리가 .인 경우는 입력과정이므로 예외
        if (!tmp.endsWith('.') || tmp.match(/\./g).length > 1) {
          tmp = parseFloat(tmp, 10)
        }
      }
      if (tmp > 100) { tmp = 100 } else if (tmp < 0) { tmp = 0 }

      nextTick(() => {
        event.target.value = tmp
        randomPoints.value[idx].weight = tmp
      })
    }

    const getPointAmount = code => {
      const item = codes.value.rewardGoods.find(x => x.code === code)
      return item ? item.pointAmount : null
    }

    // 투표형 댓글
    const showCommentSurveySelect = ref(false)
    const commentSurveySelect = {
      close: rs => {
        showCommentSurveySelect.value = false
        if (rs) {
          eventData.value.commentSurveyId = rs.id
          eventData.value.commentSurveyTitle = `[${rs.id}] ${rs.title}`
        }
      },
    }

    return {
      query,
      codes,
      eventData,
      tableColumns,
      refEventTable,
      sortBy,
      isSortDesc,
      required,
      requiredIf,
      before,
      dateBetween,
      refetchData,
      totalRecords,
      fetchEvents,
      resetEventData,
      onRowSelectedEvent,
      saveEvent,
      removeEvent,
      inputThumnail,
      inputFiles,
      readContentFile,
      showModal,
      hasEventContents,
      previewFrame,
      downloadContent,
      downloadThumbnail,
      downloadAttachFile,
      contentFile,
      thumnailFile,
      attachFiles,
      deletePrevThumnail,
      deleteAttachFile,
      fetchEventType,
      changeEventType,
      cleave,
      isSaving,
      previewUrl,
      loadPreview,
      changeAllowNonMember,
      changeRandomRewardType,
      randomPoints,
      addRandomPoint,
      removeRandomPoint,
      inputPopupImage,
      downloadPopupImage,
      popupImageFile,
      checkPopupDateFr,
      fetchRewardGoods,
      formatPoint,
      updatePoint,
      updateRandomPoint,
      updatePercent,
      getPointAmount,
      showCommentSurveySelect,
      commentSurveySelect,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
#eventPreview .modal-dialog {
  max-width: 1304px;
}
.tooltip .tooltip-inner{
  max-width: 440px !important;
}
</style>
